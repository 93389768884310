"use client";

import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";

import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import content from '../../../../../../index';
import { act } from "react";


type Icon = {
    src: string;
    alt: string;
};

type ItemProps = {
    link: string;
    title: string;
    target?: string;
    icon?: Icon | null;
    classText?: string;
		enabledClick?: boolean;
};

const staticUrl = "https://static-live.nmas.com.mx/nmas-video";

const blockDataLayer = {
		content_name: "",
		contentTitle: "",
		content_type: `Siguenos en`,
		event: "select_content",
		eventAction: "click",
		tipoElemento: "widget",
		tituloBloque: "AperturaLiveBlog",
		section: "video",
		tipoBloque: "",
		countWidget: "0",
		contenidosBloque: undefined,
		clickBloque: undefined,
		colorBloque: undefined,
		descripcionBloque: undefined,
		posicionBloque: undefined,
		subBloque: undefined,
		tamanoBloque: undefined,
};



const Item = ({ link, title, target = "_self", icon = null, classText= "", enabledClick = false }: ItemProps) => {

	let actionClick = {};
	const PATH = usePathname();
	if (enabledClick) {
		const ACTION = {
			...blockDataLayer,
			content_name: title,
			posicionBloque: undefined
		}
		actionClick = { onClick: () => handleDataLayerOnClick(ACTION, undefined, PATH, true) };
	}
	return (
        <Link href={link} title={title} target={target} className="flex items-center gap-x-2" { ...actionClick }>
            {
              (icon) ? (
                <Image
                  src={icon.src.replace('{static}', staticUrl)}
                  alt={icon.alt}
                  width={32}
                  height={32}
                  className="object-scale-down h-8 w-8"
                />
              ) : ""
            }
            <span className={`${classText} text-center`}>{title}</span>
        </Link>
    );
};

export default Item;
